<template>
   <main>
       <div class="pb">
            <Button icon="md-return-left" shape="circle" @click="$router.back()">返回</Button>
        </div>

        <Form ref="form" :model="form" :rules="validate" label-position="top">
            <Divider orientation="left">基本信息</Divider>
            <Row>
                <Col :span="9" :offset="3">
                    <FormItem label="名称" prop="title">
                        <Input :maxlength="45" show-word-limit v-model="form.title" class="w50" />
                    </FormItem>
                    <FormItem label="Key" prop="key">
                        <Input :maxlength="45" show-word-limit v-model="form.key" class="w50" />
                    </FormItem>
                    <FormItem label="排序权重">
                        <InputNumber :min="-32768" :max="32767" class="input-number-long" v-model="form.weight" />
                    </FormItem>
                    <FormItem label="状态">
                        <i-switch v-model="form.status" size="large" :true-value="1" :false-value="0">
                            <span slot="open">开启</span>
                            <span slot="close">关闭</span>
                        </i-switch>
                    </FormItem>
                </Col>
            </Row>

            <Divider />
            <Row>
                <Col :span="9" :offset="3">
                    <FormItem>
                        <Button type="primary" icon="md-add-circle" @click="submit" v-if="!id">确认添加</Button>
                        <Button type="success" icon="md-filing"  @click="submit" v-else>保存修改</Button>
                    </FormItem>
                </Col>
            </Row>
        </Form>
   </main>
</template>

<script>
    export default {
        data()
        {
            return {
                id  : this.$route.params.id,
                form: {
                    pid   : parseInt(this.$route.query.pid),
                    key   : '',
                    title : '',
                    weight: 0,
                    status: 1
                },
                validate: {
                    key: [
                        { required: true, message: '请输入KEY', trigger: 'blur' }
                    ],
                    title: [
                        { required: true, message: '请输入名称', trigger: 'blur' }
                    ]
                }
            }
        },
        created()
        {
            this.id && this.loadData()
        },
        methods: {
            submit()
            {
                this.form.pid = this.form.pid ? this.form.pid : 0

                this.$refs.form.validate((valid) => {
                    if (valid)
                    {
                        const url = '/authAction/' + (this.id ? 'update/id/' + this.id : 'create')
                        this.$http.post(url, this.form)
                            .then(res => {
                                this.$router.back()
                            })
                    }
                })
            },
            loadData()
            {
                this.$http.get('/authAction/detail/id/' + this.id)
                    .then(res => {
                        this.form = res
                    })
            }
        }
    }
</script>